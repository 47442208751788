<div class="wrap-auth">
  <div class="row h-100">
    <div class="col-lg-5 p-0">
      <app-layout bgImage="../../../assets/images/register-bg.png"></app-layout>
    </div>
    <div class="col-lg-7 p-0 col-12 ">
      <div class="right-section">
        <div class="right-section-content">
          <div class="onboarding-card">
            <h3 class="onboarding-card-title">Stay informed and support your profession
            </h3>
            <div class="content">
              <div class="subscription-category">
                <div class="subscription-gategory-item">
                  <h4 class="gategory-title mb-4">I want to progress my career with job updates</h4>
                  <div class="subscription-item">
                    <div class="content">
                     <h5 class="list-title">Weekly</h5>
                    </div>
                    <div class="action">
                     <label class="switch-secondary">
                      <input type="checkbox" [(ngModel)]="job_subscription.weekly_job_letter" value="weekly_job_letter" (change)="handleOnChange($event); trackSubscription($event, {'button': 'Weekly job letter', page: 'Email subscription'})">
                       <small></small>
                     </label>
                    </div>
                   </div>
                   <div class="subscription-item">
                    <div class="content">
                     <h5 class="list-title">Monthly</h5>
                    </div>
                    <div class="action">
                     <label class="switch-secondary">
                       <input type="checkbox" [(ngModel)]="job_subscription.monthly_advice" value="monthly_advice" (change)="handleOnChange($event); trackSubscription($event, {'button': 'Monthly advice', page: 'Email subscription'})">
                       <small></small>
                     </label>
                    </div>
                   </div>
                   <div class="subscription-item">
                     <div class="content">
                      <h5 class="list-title">I’d like to benefit from the latest products and services that VBD, the publisher of Vet Times, offers to support my practice.</h5>
                     </div>
                     <div class="action">
                      <label class="switch-secondary">
                        <input type="checkbox" [(ngModel)]="job_subscription.subscribe_to_vbd_offers" value="subscribe_to_vbd_offers" (change)="handleOnChange($event); trackSubscription($event, {'button': 'Subscribe to vbd offers', page: 'Email subscription'})">
                        <small></small>
                      </label>
                     </div>
                    </div>
                    <div class="subscription-item">
                      <div class="content">
                       <h5 class="list-title">I want to keep my clinical knowledge up-to-date by receiving information from carefully selected third parties that VBD partners with.</h5>
                      </div>
                      <div class="action">
                       <label class="switch-secondary">
                         <input type="checkbox" [(ngModel)]="job_subscription.subscribe_to_third_party_info" value="subscribe_to_third_party_info" (change)="handleOnChange($event); trackSubscription($event, {'button': 'Subscribe to third party info', page: 'Email subscription'})">
                         <small></small>
                       </label>
                      </div>
                     </div>
                     <div class="subscription-item">
                       <div class="content">
                        <h5 class="list-title">I’d like to support research to enable everyone in the profession to thrive by joining Vet Times’ workplace panel (this requires no regular time commitment from you).</h5>
                       </div>
                       <div class="action">
                        <label class="switch-secondary">
                          <input type="checkbox" [(ngModel)]="job_subscription.subscribe_to_workplace_panel" value="subscribe_to_workplace_panel" (change)="handleOnChange($event); trackSubscription($event, {'button': 'Subscribe to workplace panel', page: 'Email subscription'})">
                          <small></small>
                        </label>
                       </div>
                      </div>


                </div>

              </div>
            </div>
            <div class="d-flex justify-content-between gap-3">
              <a
                href="" routerLink="/subscription/email"
                class="btn btn-outline w-100 mt-lg-3 mt-2">
                Back
              </a>
              <a href=""
                 class="btn btn-auth btn-primary w-100 mt-lg-3 mt-2" (click)="saveJobSubscriptions($event); trackButtonClick('Next')">
                 <app-spinner></app-spinner>
                 <span *ngIf="!loader">Next</span>
              </a>
            </div>
          </div>
          <visit-products></visit-products>
        </div>
      </div>
    </div>
  </div>
</div>
