<div class="d-md-flex justify-content-between mb-md-3 mb-2">
<div>

</div>
<div class="d-flex align-items-center gap-3 mt-md-0 mt-3">
<button class="btn btn-sm btn-outline icon-btn" (click)="clearFilters()">Clear Filters</button>
  <button class="btn btn-sm btn-primary icon-btn" *ngIf="exportFilter" (click)="exportData()"><span class="me-2 icon">
    <img src="../../../assets/images/export-icon.svg" alt=""></span><span class="text">Export</span></button>

  </div>
</div>

<ag-grid-angular
  style="width: 100%; height: calc(100vh - 300px);"
  class="ag-theme-quartz"
  [rowData]="data"
  [columnDefs]="colDefs"
  [pagination]="pagination"
  [paginationPageSize]="paginationPageSize"
  [paginationPageSizeSelector]="paginationPageSizeSelector"
  [gridOptions]="gridOptions"
  (gridReady)="onGridReady($event)"
/>
