import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from "./auth/auth.module";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from "ngx-spinner";
import {SubscriptionsModule} from "./subscriptions/subscriptions.module";
import {LayoutModule} from "./layout/layout.module";
import {AdminModule} from "./admin/admin.module";
import {ReportsModule} from "./admin/reports/reports.module";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    SubscriptionsModule,
    AdminModule,
    ReportsModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
  ],
  providers: [],

  bootstrap: [AppComponent]
})
export class AppModule {
}
