import {Component} from '@angular/core';
import {ColDef} from 'ag-grid-community';
import {formatDateWithTime} from '../../helpers/my_helper';
import {ButtonRendererComponent} from "../../shared/components/renderer/button-renderer.component";
import {Router} from "@angular/router";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'members',
  templateUrl: './members.component.html',
  styleUrl: './members.component.scss'
})
export class MembersComponent {

  constructor(private router: Router) {
  }

  url = environment.myAccountBaseUrl + `user/get-users`;

  colDefs: ColDef[] = [
    {headerName: 'First Name', field: 'name', filter: true, floatingFilter: true},
    {headerName: 'Last Name', field: 'last_name', filter: true, floatingFilter: true},
    {headerName: 'Email', field: 'email', filter: true, floatingFilter: true, flex: 1},
    {headerName: 'Country', field: 'country_of_residence', filter: true, floatingFilter: true},
    {headerName: 'Phone', field: 'phone_number', filter: true, floatingFilter: true},
    {headerName: 'Job Role', field: 'job_role', filter: true, floatingFilter: true},
    {
      headerName: 'Last login date', field: 'access_logs.created_at', valueFormatter: (params: any) => formatDateWithTime(params.value),
      filterParams: {
        filterOptions: ['equals', 'inRange'],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          const cellDate = new Date(cellValue);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },
    {
      headerName: 'Last activity', field: 'updated_at', valueFormatter: (params: any) => formatDateWithTime(params.value), floatingFilter: true, filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'inRange'],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          const cellDate = new Date(cellValue);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },
    {
      headerName: 'Actions',
      cellRenderer: ButtonRendererComponent,
      cellRendererParams: {
        onClick: this.handleVerify.bind(this),
        label: 'View log',
      },
    },
  ];

  handleVerify(data: any): void {
    this.router.navigate([`/admin/activity-logs/${data.rowData.user_id}`]);
  }

}
