import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {map} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import { monthNames } from '../../helpers/my_helper';

@Injectable({
  providedIn: 'root'
})
export class KpiReportingService {

  constructor(private apiService: ApiService, private cookieService: CookieService) { }

  token = this.cookieService.get('sso_signed');

  kpiReporting(data: any) {
    if (!this.token) return;

    let headers = {
      Authorization: this.token
    };

    return this.apiService.httpPostMyAccount('report/kpi/reporting', data, headers).pipe(map(response => {
      const res = (response as any).data;
      const data = this.setDataInChart(res)
      return data;
    }));
  }

  setDataInChart(data: any) {

    const { kpireportingData, monthlyKPIData } = data;
    const [accountCreated, accountsValidated, loggedInSessions, uniqueLoggedInUsers, averageSessions] = kpireportingData.map((item: any) => item?.value || 0);

    let totalAccounts = 0;

    const monthData = monthNames.reduce((acc: any, monthName) => {
      const totalAccountsCreated = monthlyKPIData.find((item: any) => item.month === monthName)?.totalAccountsCreated || 0;
      acc[monthName] = totalAccountsCreated;
      totalAccounts += totalAccountsCreated;
      return acc;
    }, {})

    const createChartData = (dataMap: any) => [{ category: "", ...dataMap }];

    return {
      loggedInSessionsBarChartData: createChartData({ logged_in_sessions: loggedInSessions, unique_logged_in_users: uniqueLoggedInUsers, accounts_validated: accountsValidated }),
      averageSessionsBarChartData: createChartData({ account_created: accountCreated, unique_logged_in_users: uniqueLoggedInUsers, average_sessions: averageSessions }),
      barChartMonthlyData: createChartData(monthData),
      loggedInUser: loggedInSessions,
      averageSession: averageSessions,
      totalAccounts: totalAccounts,
      kpireportingData
    };
  }
}
