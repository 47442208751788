import { Component, Inject, Input, NgZone, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { areJSONEqual } from '../../../helpers/my_helper';
import { ChartService } from '../../services/chart.service';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss'
})

export class BarChartComponent {

  @Input() props: any
  private root!: any;
  public chart!: am5xy.XYChart;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone, public chartService: ChartService) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let curr_data = changes['props'].currentValue.data
    let prev_data = changes['props'].previousValue?.data

    if (!areJSONEqual(curr_data[0], prev_data ? prev_data[0] : {})) {
      this.updateChartData(curr_data)
    }

  }

  ngAfterViewInit() {
    this.implementBarChart()
  }

  updateChartData(newData: any): void {
    if (!this.root) return;

    this.chart = this.root.container.children.getIndex(0) as am5xy.XYChart;

    newData = this.chartService.handleModal(this.root, newData);

    (this.chart.xAxes.getIndex(0) as am5xy.CategoryAxis<any>).data.setAll(newData);

    this.createSeries(newData);
    this.chartService.updateBarAndLineChartLegend(this.root, this.chart);
  }

  createSeries(data: any[]): void {
    const colors = ["#ac1d54", "#1078c0", "#F2AA6B"];
    let colorIndex = 0;

    this.chart.series.clear();
    const data_length = Object.keys(data[0]).length > 4;

    Object.keys(data[0]).forEach((key) => {
      if (key !== "category") {
        const series: any = this.chart.series.push(
          am5xy.ColumnSeries.new(this.root, {
            name: key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()),
            xAxis: this.chart.xAxes.getIndex(0),
            yAxis: this.chart.yAxes.getIndex(0),
            valueYField: key,
            categoryXField: "category",
            tooltip: am5.Tooltip.new(this.root, { labelText: "{valueY}" }),
          })
        );
        if (!data_length) {
          series.set("fill", am5.color(colors[colorIndex++]));
        }
        series.columns.template.set("tooltipText", "{valueY}");
        series.data.setAll(data);
      }
    });
  }

  implementBarChart(){
    this.root = am5.Root.new(this.props.element);
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, { panY: false, layout: this.root.verticalLayout })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {})
      })
    );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        categoryField: "category"
      })
    );
    xAxis.data.setAll([]);
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }
}
