import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-button-renderer',
  standalone: true,
  imports: [CommonModule],
  template: `
    <button *ngIf="params.onClick" type="button" class="btn btn-outline-primary btn-sm border-0" (click)="handleAction('onClick', $event)">{{label}}</button>
    <button *ngIf="params.onDeleteUser" type="button" class="border-0 bg-transparent delete-btn" (click)="handleAction('onDeleteUser', $event)">
    <img src="assets/images/delete.png">
    </button>
    <button *ngIf="params.onChangePasswordUser" type="button" class="delete-btn border-0 bg-transparent" (click)="handleAction('onChangePasswordUser', $event)">
    <img src="assets/images/password-change.png">
    </button>
    `
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

  params:any;
  label: string | null = null;

  agInit(params:ICellRendererParams): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  handleAction(action: 'onClick' | 'onDeleteUser' | 'onChangePasswordUser', event: Event): void {
    const callback = this.params[action];
    if (typeof callback === 'function') {
      callback({ event, rowData: this.params.node.data });
    }
  }
}
