import { Injectable } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';

@Injectable({
  providedIn: 'root'
})

export class ChartService {

  public legend: any;
  public root: any;
  public chart: any;

  constructor() {
    window.addEventListener('resize', () => { this._barAndLineChartlegendScreenSize() });
  }

  handleModal(root: any, newData: any): any {
    this.root = root;
    const modal = root.modal ||= am5.Modal.new(root, { content: "No data available for this period" });

    const hasData = newData.every((item: any) => Object.values(item).every(value => !value));

    if (hasData) {
        modal.open();
        newData = this._normalizeData(newData);
    } else {
        modal.close();
    }

    return newData;
  }

  _normalizeData(data: any[]) {
    return data.map((item) =>
      Object.fromEntries(
        Object.entries(item).map(([key, value]) => [key, value || 1])
      )
    );
  }

  updateBarAndLineChartLegend(root: any, chart: any): void {

    this.root = root;
    this.chart = chart;
    const values = chart.series.values;

    this.legend = this.chart.children.values.find((child: any) => child instanceof am5.Legend) as am5.Legend ||
                 this.chart.children.push(am5.Legend.new(root, {})) as am5.Legend;

    this.legend.setAll({
      centerX: am5.percent(-4),
      centerY: am5.percent(100),
      width: am5.percent(100)
    });

    this._barAndLineChartlegendScreenSize()

    return this.legend.data.setAll(values);
  }

  _barAndLineChartlegendScreenSize() {
    let screen = window.innerWidth > 1200;
    const isLargeSeries = this.chart.series.length > 4;

    if (isLargeSeries) {
      screen ? this.legend.set("layout", this.root.horizontalLayout) : this.legend.remove("layout");
      this.legend.itemContainers.template.set("width", am5.percent(100 / 12));
    } else {
      this.legend.set("x", am5.percent(10));
    }
  }
}
