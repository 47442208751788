import {Injectable, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from "./api.service";
import {AuthService} from './auth.service';
import Swal from 'sweetalert2';
import { DatatableComponent } from '../../shared/components/datatable/datatable.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  @ViewChild(DatatableComponent) datatable!: DatatableComponent;

  constructor(private apiService: ApiService, private authService: AuthService) {
  }

  public header: any = {
    Authorization: this.authService.getToken,
  }

  getUsers(page: number = 1, limit: number = 10): Observable<any> {
    return this.apiService.httpGetMyAccount(`user/get-users?page=${page}&limit=${limit}`, this.header);
  }

  getUnverifiedUsers(page: number = 1, limit: number = 10): Observable<any> {
    return this.apiService.httpGetMyAccount(`user/get-unverified-users?page=${page}&limit=${limit}`, this.header);
  }

  verifyUser(userId: number): Observable<any> {
    return this.apiService.httpPostMyAccount(`user/verify-user`, {user_id: userId}, this.header);
  }

  getUser() {
    return this.apiService.httpGetMyAccount('user/get-user', this.header);
  }

  updateUser(url: string, body: any): Observable<any> {
    return this.apiService.httpPostMyAccount(url, body, this.header);
  }

  deleteUser(user_id: number) {
    return this.apiService.httpDeleteMyAccount(`admin/delete-user/${user_id}`, this.header)
  }

  getNewPasswordValues(): { newPassword: string; confirmPassword: string } | undefined {
    const newPassword = (document.getElementById('newPassword') as HTMLInputElement).value;
    const confirmPassword = (document.getElementById('confirmPassword') as HTMLInputElement).value;

    if (!newPassword || !confirmPassword) {
      Swal.showValidationMessage('Please enter all fields');
      return;
    }

    if (newPassword.length < 8) {
      Swal.showValidationMessage(`Password must be between 8 characters`);
      return;
    }

    if (newPassword !== confirmPassword) {
      Swal.showValidationMessage('New passwords do not match');
      return;
    }

    return { newPassword, confirmPassword };
  }

  updateUserPassword(userId: string, newPassword: string, confirmPassword: string): void {
    const body = { user_id: userId, new_password: newPassword, confirm_password: confirmPassword };
    this.updateUser('admin/change-password', body).subscribe(
      () => Swal.fire('Success', 'Your password has been changed', 'success'),
      () => Swal.fire('Error', 'There was an error changing the password', 'error')
    );
  }

  confirmUpdate(event: any): void {
    Swal.fire({
        title: 'Are you sure?',
        html: `You want to update ${String(event.colDef.headerName).toLocaleLowerCase()} for this user.`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
    }).then(result => {
        if (result.isConfirmed) {
          this.updateUserDetail(event);
        }
    });
  }

  updateUserDetail(event: any): void {
    const body = {
      user_id: event.data.user_id,
      [String(event.colDef.field)]: event.newValue
    };
    this.updateUser('admin/update-user', body).subscribe(
        response => {
            console.log("response", response);
            Swal.fire('Success', `The ${String(event.colDef.headerName)} has been updated`, 'success');
            this.datatable.refreshData();
        },
        error => {
            const errMsg = error.error.errors[String(event.colDef.field)] ?? error.error.errors
            Swal.fire('Error!', errMsg, 'error');
            console.error(`Error updating ${String(event.colDef.headerName)}`, error);
        }
    );
  }
}
