import { Component, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { createColumn, createDateColumn } from '../../helpers/my_helper';
import { ButtonRendererComponent } from '../../shared/components/renderer/button-renderer.component';
import { DatatableComponent } from '../../shared/components/datatable/datatable.component';
import { UserService } from '../../shared/services/user.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'members',
  templateUrl: './members.component.html',
  styleUrl: './members.component.scss'
})
export class MembersComponent {
  @ViewChild(DatatableComponent) datatable!: DatatableComponent;

  url = `${environment.myAccountBaseUrl}user/get-users`;

  colDefs: ColDef[] = [
    createColumn('First Name', 'name'),
    createColumn('Last Name', 'last_name'),
    createColumn('Email', 'email', {editable: true, cellEditor: 'agTextCellEditor', cellEditorParams: { maxLength: 150 }}),
    createColumn('Country', 'country_of_residence'),
    createColumn('Phone', 'phone_number'),
    createColumn('Job Role', 'job_role'),
    createDateColumn('Last login date', 'access_logs.created_at'),
    createDateColumn('Last activity', 'updated_at', true),
    {
      headerName: 'Actions',
      cellRenderer: ButtonRendererComponent,
      cellRendererParams: {
        label: 'View log',
        onClick: (data: any) => this.handleVerify(data),
        onDeleteUser: (data: any) => this.handleDeleteUser(data),
        onChangePasswordUser: (data: any) => this.handleChangePasswordUser(data),
      },
    },
  ];

  constructor(private router: Router, private userService: UserService) {}

  handleVerify(data: any): void {
    this.router.navigate([`/admin/activity-logs/${data.rowData.user_id}`]);
  }

  handleDeleteUser(data: any): void {
    Swal.fire({
      title: 'Are you sure?',
      html: 'You want to delete this user.<br><br><strong>Note:</strong> Once deleted, it cannot be reverted.',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(result => {
      if (result.isConfirmed) {
        this.userService.deleteUser(data.rowData.user_id).subscribe(
          response => {
            console.log("response", response);
            Swal.fire('Success', 'The user has been deleted', 'success');
            this.datatable.refreshData();
          },
          error => {
            Swal.fire('Error!', 'There was an error deleting the user', 'error');
            console.error('Error deleting user', error);
          }
        );
      }
    });
  }

  handleChangePasswordUser(data: any): void {
    Swal.fire({
      title: 'Change Password',
      html: `
      <div class="mb-3">
        <p class="text-black text-start mb-2">New Password</p>
        <input type="password" id="newPassword" class="swal2-input m-0 w-100" placeholder="New Password">
      </div>
      <div>
      <p class="text-black text-start mb-2">Confirm New Password</p>
      <input type="password" id="confirmPassword" class="swal2-input m-0 w-100" placeholder="Confirm New Password">
      </div>
      `,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => this.userService.getNewPasswordValues(),
    }).then(result => {
      if (result.isConfirmed) {
        this.userService.updateUserPassword(data.rowData.user_id, result.value.newPassword, result.value.confirmPassword);
      }
    });
  }
}
