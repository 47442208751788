import {Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import {NgbModal, NgbModalConfig, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from "../../shared/services/api.service";
import {NgxSpinnerService} from "ngx-spinner";
import {CookieService} from "ngx-cookie-service";
import {FormBuilder, FormGroup} from "@angular/forms";
import Swal from "sweetalert2";
import {environment} from '../../../environments/environment';
import {GoogleAnalyticsService} from '../../shared/services/google-analytics.service';
import {SubscriptionService} from "../../shared/services/subscription.service";
import {Router} from "@angular/router";
import {UserService} from "../../shared/services/user.service";
import {EmailSubscriptionValidation} from "../../shared/validations/email-subscription.validation";


@Component({
  selector: 'app-email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})

export class EmailSubscriptionComponent {
  ngbModalOptions: NgbModalOptions = {keyboard: false, centered: true, scrollable: true};
  printsubsRef!: NgbModalRef;
  @ViewChild('printSubscription') printsubs!: ElementRef;
  @ViewChild('postcodeInput') postcodeInput!: ElementRef | undefined;

  public user = localStorage.getItem('user') || '';

  public addresses: any = []

  public show_address_list: boolean = false

  public loader: boolean = false

  public print_subscription_loader: boolean = false

  public steps: { [key: string]: boolean } = {
    'subscription': true,
    'personal_details': false,
  }

  form: FormGroup;

  loaders: { [key: string]: boolean } = {};

  job_role: string = '';

  show_print_subscriptions: boolean = true;
  show_print_vettimes: boolean = false;
  show_print_vntimes: boolean = false;
  show_print_vbj: boolean = false;

  is_print_subs_checked: boolean = false;

  subscriptions = {
    weekly_vt_letter: false,
    daily_vt_letter: false,
    monthly_vnt_letter: false,
    vbd_updates: false,
    industry_updates: false,
  };

  constructor(private modalService: NgbModal, private apiService: ApiService, private googleAnalytics: GoogleAnalyticsService, private spinner: NgxSpinnerService,
              private cookieService: CookieService, private fb: FormBuilder, private subscriptionService: SubscriptionService, private router: Router,
              private userService: UserService) {

    this.form = EmailSubscriptionValidation.createPrintSubscriptionForm(this.fb);
    this.loadEmailSubscription()
  }

  ngOnInit(): void {
    let user = JSON.parse(this.user);
    this.job_role = user.profile.job_role
    this.getCountries(user.profile.country_of_residence);
  }

  @HostListener('document:click', ['$event'])

  onClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;

    // Check if click is outside of the search field and dropdown list
    if (!this.postcodeInput?.nativeElement.contains(clickedElement) &&
      !document.getElementById('address-list')?.contains(clickedElement)) {
      this.show_address_list = false;
    }
  }

  loadEmailSubscription() {
    this.userService.getUser().subscribe((res: any) => {
      this.subscriptions.weekly_vt_letter = res.data.weekly_vt_letter
      this.subscriptions.daily_vt_letter = res.data.daily_vt_letter
      this.subscriptions.monthly_vnt_letter = res.data.monthly_vnt_letter
      this.subscriptions.vbd_updates = res.data.vbd_updates
      this.subscriptions.industry_updates = res.data.industry_updates
    })
  }

  trackButtonClick(btn_name: string) {
    let json: any = {'button': btn_name, page: 'Email subscription'}
    if (this.steps['personal_details']) {
      this.form.markAllAsTouched();
      if (this.form.invalid) return
      json['payload'] = this.form.value
    }
    this.googleAnalytics.trackButtonClick(json);
  }

  saveEmailSubscription(event: Event) {
    event.preventDefault();
    this.spinner.show()
    this.loader = true;
    this.subscriptionService.saveEmailSubscription(this.subscriptions).subscribe((res: any) => {
      this.trackButtonClick('Next')
      this.spinner.hide();
      this.router.navigate(['/subscription/setting']);
    }, error => {
      console.error(error);
      this.spinner.hide();
      this.loader = false;
      // inputElement.checked = !checked;
    })
  }

  trackSubscription(event: any, json: any) {
    json.status = event.target.checked ? 'Subscribe' : 'Unsubscribe'
    this.googleAnalytics.trackButtonClick(json);
  }

  getCountries(country_of_residence: any) {
    this.apiService.httpGetMyAccount('user/countries').subscribe(
      (res: any) => {
        const user = JSON.parse(this.user);
        const jobRole = user.profile.job_role;
        const country = res.data.find((c: any) => c.country_name === country_of_residence);
        const isUK = (country?.country_code?.startsWith('GB') || country_of_residence === 'Uk');

        this.show_print_subscriptions = isUK && (jobRole === 'C' || jobRole === 'D');
        this.show_print_vettimes = isUK && jobRole === 'C';
        this.show_print_vntimes = isUK && jobRole === 'D';
        this.show_print_vbj = isUK && jobRole === 'C';
      },
      (err: any) => console.error('Error fetching countries', err)
    );
  }

  findRcvsNumber(e: any): void {
    e.preventDefault();
    window.open(environment.rcvsUrl, '_blank');
  }

  validateRcvsInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    let validValue = value.charAt(0).replace(/[^a-zA-Z0-9]/, '');

    validValue += value.slice(1, 6).replace(/[^0-9]/g, '');

    if (value.length > 6) {
      validValue += value.charAt(6).replace(/[^a-zA-Z0-9]/, '');
    }

    validValue = validValue.slice(0, 7);

    if (value !== validValue) {
      this.form.get('rcvs')?.setValue(validValue, {emitEvent: false});
    }
  }

  openPrintSubs() {
    this.printsubsRef = this.modalService.open(this.printsubs, this.ngbModalOptions);
  }

  getPrintSubsLabel(controlName: string): string {
    const labels: { [key: string]: string } = {
      print_vettimes: 'Vet Times',
      print_vntimes: 'VN Times',
      print_vbj: 'VBJ'
    };
    return labels[controlName];
  }

  closePrintSubs() {
    this.steps['subscription'] = true;
    this.steps['personal_details'] = false;
    this.printsubsRef.close()
  }

  searchPostcode(postcodeInput: HTMLInputElement) {
    this.loader = true
    this.spinner.show()
    const postcode = postcodeInput.value;
    this.apiService.httpGetMyAccount('location/search/postcode/' + postcode).subscribe((res: any) => {
      this.show_address_list = true
      this.loader = false
      this.spinner.hide()
      this.addresses = []
      res.data.addresses.forEach((address: any) => {
        this.addresses.push({
          display: address.formatted_address.join(" "),
          address1: address.line_1,
          address2:
            address.line_2 === "" &&
            address.country !== "Isle of Man" &&
            address.country !== "Channel Islands"
              ? address.locality
              : address.line_2,
          address3: address.line_3,
          town:
            address.country === "Isle of Man" ||
            address.country === "Channel Islands"
              ? address.locality
              : address.town_or_city,
          county:
            address.town_or_city === "Isle of Man" ||
            address.country === "Channel Islands"
              ? address.town_or_city
              : address.county,
          country:
            address.country === "Isle of Man" ? "England" : address.country,
          postcode: res.data.postcode,
        });
      })
    }, error => {
      this.loader = false
      this.spinner.hide()
      this.show_address_list = true
      this.addresses = []
      this.addresses[0] = {display: "No records found"}
    })
  }

  selectAddress(data: any) {
    if (data.display != 'No records found') {
      this.form.patchValue({
        address_1: data.address1,
        address_2: data.address2,
        address_3: data.address3,
        town: data.town,
        county: data.county,
        country: data.country,
        postcode: data.postcode,
      });
    }
    this.show_address_list = false;
  }

  back() {
    this.steps['subscription'] = true
    this.steps['personal_details'] = false
  }

  next() {
    if (!this.is_print_subs_checked) {
      this.savePrintSubscriptions()
    } else if (this.steps['personal_details']) {
      this.handleSubscribe()
    } else {
      this.steps['subscription'] = false
      this.steps['personal_details'] = true
    }
  }

  removeEmptyValues(payload: any) {
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (payload[key] === null || payload[key] === undefined || payload[key] === "") {
          delete payload[key];
        }
      }
    }
    return payload;
  }

  // Utility function to trim all form values
  trimFormValues(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      if (control && control.value && typeof control.value === 'string') {
        control.setValue(control.value.trim(), {emitEvent: false});
      }
    });
  }

  handleSubscribe() {
    this.trimFormValues(this.form);
    this.form.markAllAsTouched();

    if (this.form.invalid) return

    this.savePrintSubscriptions()
  }

  savePrintSubscriptions() {
    let token = this.cookieService.get('sso_signed')

    if (token) {

      const payload = this.removeEmptyValues(this.form.value);

      payload.print_vettimes = payload.print_vettimes ? 1 : 0;
      payload.print_vntimes = payload.print_vntimes ? 1 : 0;
      payload.print_vbj = payload.print_vbj ? 1 : 0;

      let headers = {Authorization: token}

      this.spinner.show("print_subscription_spinner")
      this.print_subscription_loader = true;
      this.apiService.httpPostMyAccount('user/update-user', payload, headers).subscribe((data: any) => {
        this.spinner.hide("print_subscription_spinner")
        this.print_subscription_loader = false
        this.closePrintSubs();
        const selectedPrintSubscriptions = Object.keys(this.form.value).filter(key => this.form.value[key]).map(key => this.getPrintSubsLabel(key)).filter(label => label !== undefined)
        Swal.fire({
          title: "Success!",
          html: selectedPrintSubscriptions.length > 0 ? `You have successfully subscribed: <strong>${selectedPrintSubscriptions.join(', ')}</strong>` : 'You have successfully updated your print subscription.',
          icon: "success",
        });
      })
    }
  }

  onCheckboxChange(event: Event): void {
    this.is_print_subs_checked = !!(this.form.value.print_vettimes || this.form.value.print_vbj || this.form.value.print_vntimes);
  }
}
