import { ColDef } from 'ag-grid-community';

export function formatDateWithTime(value: any): string {
  if (value) {
    const date = new Date(value);
    return date.toLocaleString();
  }
  return '';
}

export function subscriptionsField(subscription: string): string {
  let subscriptions: any = {
    weekly_vt_letter: 'Vet Times Weekly Newsletter',
    daily_vt_letter: 'Vet Times Daily Digest',
    monthly_vnt_letter: 'VN Times Monthly Newsletter',
    vbd_updates: 'VBD Updates',
    industry_updates: 'Industry Updates',
    print_vettimes: 'Vet Times',
    print_vntimes: 'VN Times',
    print_vbj: 'VBJ',
    weekly_job_letter: "Weekly",
    monthly_advice: "Monthly",
    subscribe_to_vbd_offers: "Subscribe to VBD Offers",
    subscribe_to_third_party_info: "Subscribe to third party info",
    subscribe_to_workplace_panel: "Subscribe to workplace panel"
  };

  return subscriptions[subscription];
}

export const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export function areJSONEqual(json1: JSON, json2: JSON) {
  return JSON.stringify(json1) === JSON.stringify(json2);
}

export function createColumn(headerName: string, field: string, extraProps: Partial<ColDef> = {}): ColDef {
  return { headerName, field, filter: true, floatingFilter: true, ...extraProps };
}

export function createDateColumn(headerName: string, field: string, floatingFilter = false): ColDef {
  return {
    headerName,
    field,
    valueFormatter: (params: any) => formatDateWithTime(params.value),
    filter: 'agDateColumnFilter',
    floatingFilter,
    filterParams: {
      filterOptions: ['equals', 'inRange'],
      comparator: (filterDate: any, cellValue: any) => dateComparator(filterDate, cellValue),
    },
  };
}

export function dateComparator(filterDate: Date, cellValue: any): number {
  const cellDate = new Date(cellValue);
  return cellDate < filterDate ? -1 : cellDate > filterDate ? 1 : 0;
}
